const categories = [
  {
    label: "Please select nomination category",
    value: "default",
    definition: "",
  },
  {
    label: "Best Restaurant",
    value: "Best Restaurant",
    categoryId: "1",
    definition:
      "We are looking for a restaurant that ticks all the boxes – exemplary customer service, wonderful atmosphere and of course, outstanding food. Nominate a place that you would recommend to anyone who wants to impress their guests, and that you would return to time and time again.",
  },
  {
    label: "Best Chef",
    value: "Best Chef",
    categoryId: "2",
    definition:
      "This award represents the chef who demonstrates an immense passion for food – someone who is committed to quality and the pursuit of creative new dining experiences. The winner will introduce their diners to exciting new flavour combinations, and consistently produces top quality dishes.",
    customFields: [
      {
        label: "Best Chef Name",
        field: "best_chef_name",
      },
    ],
  },
  {
    label: "Best Restaurant Manager",
    value: "Best Restaurant Manager",
    categoryId: "6",
    definition:
      "This award recognises the individual that oversees the day to day operation of the restaurant with enthusiasm and expertise. They will be an exceptional leader for their team and will be able to handle any situation that arises. They will demonstrate strong business acumen, working knowledge of the industry and an intimate knowledge of the menu and wines being offered. The restaurant manager will be the face of the establishment and will have a good rapport with both new and returning customers.",
    customFields: [
      {
        label: "Best Restaurant Manager Name",
        field: "best_restaurant_manager_name",
      },
    ],
  },
  {
    label: "Best Hotel & Guesthouse Restaurant",
    value: "Best Hotel & Guesthouse Restaurant",
    categoryId: "5",
    definition:
      "The best hotel & guesthouse restaurant will encompass all the qualities of great hospitality – exemplary customer service, top quality food and a welcoming and relaxing atmosphere. We are looking for somewhere that extends its outstanding hospitality to both residents and walk-in guests alike.",
    customFields: [
      {
        label: "Best Hotel & Guesthouse Restaurant Name",
        field: "best_hotel_guesthouse_restaurant_name",
      },
    ],
  },
  {
    label: "Employee Excellence Award",
    value: "Employee Excellence Award",
    categoryId: "22",
    definition: `This award will highlight employees working within the hospitality industry that demonstrate remarkable on-the-job excellence in term of work quality, customer service skills, teamwork and innovation. This category provides an opportunity for an employee’s job performance to be recognised & rewarded in a meaningful manner.`,
    customFields: [
      {
        label: "Employee Name",
        field: "employee_excellence_name",
      },
    ],
  },
  {
    label: "Pub of the Year",
    value: "Pub of the Year",
    categoryId: "10",
    definition:
      "The Pub of the Year is one that caters to all tastes – from whiskey lovers to craft beer enthusiasts. A wide selection of quality beverages should be on offer. The atmosphere should be comfortable and welcoming with that traditional ‘pub feel’. Staff will value their customers and be able to recommend drinks without hesitation. The winner should be popular with locals and visitors alike, and should inspire high standards.",
  },
  {
    label: "Best Casual Dining",
    value: "Best Casual Dining",
    categoryId: "7",
    definition:
      "We are on the search for the best casual dining establishment offering top quality food, great customer service and genuine hospitality. Whether it is table service or order at a counter, the atmosphere should be relaxed and informal. Décor, style of food and culture of each establishment will vary widely but should all complement each other within each restaurant.",
  },
  {
    label: "Best Gastro Pub",
    value: "Best Gastro Pub",
    categoryId: "3",
    definition:
      "This award recognises the gastro pub that offers an innovative menu of food which suitably complements their drink selection. The Best Gastropub will provide restaurant quality dining, whilst not compromising on the traditional ‘pub’ atmosphere.",
  },
  {
    label: "Best Contemporary Irish Cuisine",
    value: "Best Contemporary Irish Cuisine",
    categoryId: "11",
    definition:
      "This award will go to the restaurant that displays a genuine commitment to actively influencing the future of Irish cuisine by encouraging us to try something new, while simultaneously reminding us of our heritage. The menu will showcase the best of Irish produce from the land and sea. Traditional Irish dishes may be rejuvenated with a modern twist. Unique and modern ingredients, methods and styles of cooking may be used.",
  },
  {
    label: "Best Newcomer",
    value: "Best Newcomer",
    categoryId: "8",
    definition:
      "This award represents the best break-through establishment that has been in operation since 2021 (opened in/after June 2021) – offering an impressive dining experience, innovative menu and has had significant impact in the industry within a short time. They will have proven marketing abilities and a loyal customer following.",
  },
  {
    label: "Best Wine Experience",
    value: "Best Wine Experience",
    categoryId: "4",
    definition:
      "This award recognises the restaurant that prides itself on its wine selection. An interesting array of wines will be on offer to cater for a wide range of wine lovers, and will be appropriate for the type of cuisine served. There should be a well-chosen selection from quality producers that provides a thematic match to the menu in terms of both price and style.",
  },
  {
    label: "Best Customer Service",
    value: "Best Customer Service",
    categoryId: "9",
    definition:
      "The winner of this award will be one that consistently goes above and beyond the call of duty to its customers. Staff will be attentive, friendly and prompt, but not overbearing. It will be evident that they have been trained in the art of great customer service, and they will also be knowledgeable of both the food and wine menus. They will be professional and well presented at all times, and represent the customs and culture of the restaurant.",
  },
  {
    label: "Best Café",
    value: "Best Café",
    categoryId: "13",
    definition:
      "This award represents the establishment that strives to provide the best coffee and dining experience, using ethically sourced ingredients and delivering outstanding customer service. Knowledgeable baristas, great quality food and a warm and inviting atmosphere. Nominate your go-to place for a casual lunch with friends, or a quick bite on the go.",
  },
  {
    label: "Food Truck of the Year",
    value: "Food Truck of the Year",
    categoryId: "14",
    definition: `The award seeks to celebrate Ireland’s Food Truck Offering. The category will recognise food truck/container style etc. establishments who demonstrate creativity & innovation on a daily basis within their contemporary structures. In particular, they should possess a quality food offering for their customers, as well as excellence customer service from all team members.`,
  },
  {
    label: "Best World Cuisine",
    value: "Best World Cuisine",
    categoryId: "12",
    definition:
      "We are looking for a restaurant that provides a genuine international experience for their diners. The winner should provide outstanding food, excellent customer service and a real insight into their culture through both the dishes and the decor. We want to see what global delights are available right on our own doorstep.",
  },
  {
    label: "Best Cocktail Experience",
    value: "Best Cocktail Experience",
    categoryId: "16",
    definition:
      "This award goes to the restaurant or bar which brings a creative and exciting approach to cocktails, coupled with flair and imaginative presentation. Nominees for Best Cocktail Experience will be invited to submit an original cocktail recipe and the next stage is a live cocktail competition where the drinks will be made and presented to a panel of experts.",
  },
 {
    label: "Best Use of Social Media Award",
    value: "Best Use of Social Media Award",
    categoryId: "17",
    definition:
      "This award recognises hospitality establishments who have used social media to inspire, inform, entertain & encourage custom and interaction among their patrons. This can include campaigns surrounding a viral food trend, a recipe demonstration, or a community-driven foodie forum for example. This category is looking to recognise social media campaigns (across the use of platforms such a Facebook / Instagram / X / Tiktok / Linkedin etc.) that are setting the bar for social media excellence in the hospitality industry.",
  },
  {
    label: "Best Sustainable Practices",
    value: "Best Sustainable Practices",
    categoryId: "21",
    definition:
      "This award goes to the hospitality establishment engaging in sustainable practices & activities in the day to day running of their business.",
  },
  {
    label: "Best Cookery School",
    value: "Best Cookery School",
    categoryId: "18",
    definition:
      "This award goes to the cookery school who contributes greatly to food culture in Ireland by educating its students in nutrition and best practice in the preparation, cooking and presentation of food to a high standard. The winning school will have the ability to foster a passion for culinary arts within its students from experienced chefs and tutors. The school will produce a high standard of graduates who have the necessary skills and techniques to go on and become successful chefs.",
  },
  {
    label: "Innovator of the Year",
    value: "Innovator of the Year",
    categoryId: "19",
    definition:
      "The award seeks to highlight restaurants/gastro pubs/cafes who demonstrate excellence and creativity on a daily basis within their venues. In particular, this creativity and innovation should be seen within the design & development of their menus, as well in the overall running of the establishment.",
  },
  {
    label: "Local Food Hero",
    value: "Local Food Hero",
    categoryId: "15",
    definition: `Please note that this is a Regional Category – there will be no county winners – only one winner per region
    The Local Food Hero award recognises the individual that promotes food culture in their locality and inspires people to get involved with food. They may be actively encouraging the restaurants in their area to use local produce, or perhaps they are working to attract food tourists by creating food trails and markets. Or maybe they produce the finest quality ingredients.`,
    customFields: [
      {
        label: "Local Food Hero Name",
        field: "best_hero_name",
      },
      {
        label: "Local Food Hero Description",
        field: "best_hero_description",
      },
    ],
  },
  {
    label: "Outstanding Achievement Award",
    value: "Outstanding Achievement Award",
    categoryId: "23",
    definition: `This award recognises an individual who has worked within the restaurant & hospitality sector throughout their career. The nominee should be an individual who can best demonstrate a significant contribution to the hospitality industry as a whole through their actions & achievements within their working years.`,
    customFields: [
      {
        label: "Outstanding Achievement Name",
        field: "outstanding_achievement_name",
      },
    ],
  },
  {
    label: "Best Learning & Development",
    value: "Best Learning & Development",
    categoryId: "20",
    definition:
      "This award goes to the establishment that has a strong focus on staff learning & developmental needs, including the delivery of staff training programmes in key areas. These training programmes may have been delivered in person and/or via digital avenues. This award strives to highlight excellence, best practice and innovation in learning & development within the Hospitality industry.",
  },
];

export default categories;
